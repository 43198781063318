import {
  IJoinOpportunityRequestWithRelations,
  IOpportunity,
  IOpportunityBasicInformation,
  IOpportunityFilters,
  IOpportunityMatchWithRelations,
  IOpportunityWithRelations,
} from '@opportunity/interfaces';
import {Action, Selector, State, StateContext} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {environment} from '@env/environment';
import {BaseService} from '@shared/services';
import {ErrorHandlerService} from '@shared/services/error-handler.service';
import {PrismaFilter} from '@shared/services/base/interfaces/prisma-filter.interface';
import {DataBaseServiceResponse} from '@shared/services/base/interfaces/data-base-service-response.interface';
import {firstValueFrom} from 'rxjs';
import {UtilityClass} from '@shared/utils/utility.class';
import {IBudgetRequest, IProductRequestWithRelations} from '@opportunity/interfaces/budget-request.interface';
import {NzMessageService} from 'ng-zorro-antd/message';
import {Router} from '@angular/router';
import {DocumentUtilClass} from '@document/document-util.class';
import {IOpportunityResolutionWithRelations} from '@opportunity/interfaces/opportunity-resolution.interface';

export interface IOpportunityState {
  list: IOpportunityWithRelations[];
  selectedId: string | null;
  count: number;
  opportunityMatches: IOpportunityMatchWithRelations[];
  opportunityMatchSelectedId: string | null;
  budgetRequest: IProductRequestWithRelations[];
  budgetRequestSelectedId: string | null;

  opportunitiesBasicInformation: IOpportunityBasicInformation[];

  joinOpportunityRequest: IJoinOpportunityRequestWithRelations[];
  joinOpportunityRequestSelectedId: string | null;

  resolutions: IOpportunityResolutionWithRelations[];
  resolutionsSelectedId: string | null;
}

const _DEFAULT_OPPORTUNITY: IOpportunityState = {
  list: [],
  selectedId: null,
  count: 0,
  opportunityMatches: [],
  opportunityMatchSelectedId: null,
  budgetRequest: [],
  budgetRequestSelectedId: null,
  opportunitiesBasicInformation: [],

  joinOpportunityRequest: [],
  joinOpportunityRequestSelectedId: null,

  resolutions: [],
  resolutionsSelectedId: null,
}

export namespace OpportunityActions {
  export class GetList {
    static readonly type: string = '[Opportunity] Get Opportunity List';
    constructor(public organizationId: string, public filter?: IOpportunityFilters) {}
  }

  export class GetAllList {
    static readonly type: string = '[Opportunity] Get All Opportunity List';
    constructor(public filter?: IOpportunityFilters) {}
  }

  export class CountList {
    static readonly type: string = '[Opportunity] Count Opportunity List';
    constructor(public organizationId: string, public filter?: IOpportunityFilters) {}
  }

  export class GetAllListBasicInformation {
    static readonly type: string = '[Opportunity] Get All Opportunity List Basic Information';
    constructor(public filter?: PrismaFilter<IOpportunityWithRelations>) {}
  }

  export class CountAllList {
    static readonly type: string = '[Opportunity] Count All Opportunity List';
    constructor(public filter?: IOpportunityFilters) {}
  }

  export class Create {
    static readonly type: string = '[Opportunity] Create Opportunity';
    constructor(public organizationId: string, public opportunity: Partial<IOpportunity>) {}
  }

  export class UpdateById {
    static readonly type: string = '[Product] Update Opportunity';
    constructor(public organizationId: string, public opportunityId: string, public opportunity: Partial<IOpportunity>) {}
  }

  export class UpdateAllById {
    static readonly type: string = '[Product] Update All By Id Opportunity';
    constructor(public opportunityId: string, public opportunity: Partial<IOpportunity>) {}
  }

  export class GetById {
    static readonly type: string = '[Opportunity] Get Opportunity By Id';
    constructor(public organizationId: string, public opportunityId: string, public filter?: PrismaFilter<IOpportunityWithRelations>, public setBudgetRequest?: boolean) {}
  }

  export class GetAllById {
    static readonly type: string = '[Opportunity] Get All Opportunity By Id';
    constructor(public opportunityId: string, public filter?: PrismaFilter<IOpportunityWithRelations>, public setBudgetRequest?: boolean) {}
  }

  export class DeleteById {
    static readonly type: string = '[Opportunity] Delete Opportunity';
    constructor(public organizationId: string, public opportunityId: string) {}
  }

  export class DeleteAllById {
    static readonly type: string = '[Opportunity] Delete All By Id Opportunity';
    constructor(public opportunityId: string) {}
  }

  export class SetOpportunitySelected {
    static readonly type: string = '[Opportunity] Set Opportunity By Id';
    constructor(public id: string | null) {}
  }

  export class FinalizeById {
    static readonly type: string = '[Opportunity] Finalize Opportunity';
    constructor(public organizationId: string, public opportunityId: string) {}
  }

  export class FinalizeAllById {
    static readonly type: string = '[Opportunity] Finalize All By Id Opportunity';
    constructor(public opportunityId: string) {}
  }

  export class DownloadQuoteDocument {
    static readonly type: string = '[Document] Download Quote Document';
    constructor(public opportunityId: string, public budgetRequestId: string, public fileName: string, public mimeType: string) {
    }
  }

  export class GetResolution {
    static readonly type: string = '[Opportunity] Get Resolution';
    constructor(public organizationId: string, public opportunityId: string, public resolutionId: string) {}
  }

  export class DownloadResolution {
    static readonly type: string = '[Opportunity] Download Resolution';
    constructor(public organizationId: string, public opportunityId: string, public resolutionId: string, public acceptedSupplierId: string, public fileName: string, public mimeType: string) {}
  }

  export class Reset {
    static readonly type: string = '[Opportunity] Reset Opportunity';
    constructor() {}
  }

}

@State<IOpportunityState>({
  name: 'OpportunityState',
  defaults: _DEFAULT_OPPORTUNITY
})
@Injectable()
export class OpportunityState {
  private readonly SERVER: string = environment.SERVER;

  @Selector()
  static getOpportunities({list}: IOpportunityState): IOpportunityWithRelations[] {
    return list;
  }

  @Selector()
  static getOpportunitiesBasicInformation({opportunitiesBasicInformation}: IOpportunityState): IOpportunityBasicInformation[] {
    return opportunitiesBasicInformation;
  }

  @Selector()
  static countOpportunities({count}: IOpportunityState): number {
    return count;
  }

  @Selector()
  static getOpportunitySelected({list, selectedId}: IOpportunityState): IOpportunityWithRelations | undefined {
    return list.find(({id}: IOpportunityWithRelations): boolean => id === selectedId);
  }

  @Selector()
  static getOpportunitySelectedId({selectedId}: IOpportunityState): string | null{
    return selectedId;
  }

  @Selector()
  static getOpportunityMatches({opportunityMatches}: IOpportunityState): IOpportunityMatchWithRelations[] {
    return opportunityMatches;
  }

  @Selector()
  static getJoinOpportunityRequest({joinOpportunityRequest}: IOpportunityState): IJoinOpportunityRequestWithRelations[] {
    return joinOpportunityRequest;
  }

  @Selector()
  static getJoinOpportunityRequestSelected({joinOpportunityRequest, joinOpportunityRequestSelectedId}: IOpportunityState): IJoinOpportunityRequestWithRelations | undefined {
    return joinOpportunityRequest.find((join: IJoinOpportunityRequestWithRelations) => join.id === joinOpportunityRequestSelectedId);
  }

  @Selector()
  static getOpportunityBudgetRequest({budgetRequest}: IOpportunityState): IBudgetRequest[] {
    return budgetRequest;
  }

  @Selector()
  static getOpportunityBudgetRequestSelected({budgetRequest, budgetRequestSelectedId}: IOpportunityState): IBudgetRequest | undefined {
    return budgetRequest.find((budgetRequest: IBudgetRequest) => budgetRequest.id === budgetRequestSelectedId);
  }

  @Selector()
  static getOpportunityResolutionSelected({resolutions, resolutionsSelectedId}: IOpportunityState): IOpportunityResolutionWithRelations | undefined {
    return resolutions.find((res: IOpportunityResolutionWithRelations): boolean => res.id === resolutionsSelectedId);
  }

  constructor(
    private baseService: BaseService,
    private errorHandlerService: ErrorHandlerService,
    private nzMessageService: NzMessageService,
    private router: Router
  ) {}

  @Action(OpportunityActions.GetList)
  async getOpportunitiesList(
    {patchState}: StateContext<IOpportunityState>,
    {organizationId, filter}: OpportunityActions.GetList
  ): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityWithRelations[]> = await firstValueFrom(this.baseService.get<IOpportunityWithRelations[]>(`${this.SERVER}/organizations/${organizationId}/opportunities`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({list: response.entity!});
  }

  @Action(OpportunityActions.GetAllList)
  async getAllOpportunitiesList(
    {patchState}: StateContext<IOpportunityState>,
    {filter}: OpportunityActions.GetAllList
  ): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityWithRelations[]> = await firstValueFrom(this.baseService.get<IOpportunityWithRelations[]>(`${this.SERVER}/opportunities`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({list: response.entity!});
  }

  @Action(OpportunityActions.GetAllListBasicInformation)
  async getAllOpportunitiesListBasicInformation(
    {patchState}: StateContext<IOpportunityState>,
    {filter}: OpportunityActions.GetAllListBasicInformation
  ): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityBasicInformation[]> = await firstValueFrom(this.baseService.get<IOpportunityBasicInformation[]>(`${this.SERVER}/opportunities/basic-information`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({opportunitiesBasicInformation: response.entity ?? []});
  }

  @Action(OpportunityActions.CountList)
  async countOpportunitiesList(
    {patchState}: StateContext<IOpportunityState>,
    {organizationId, filter}: OpportunityActions.CountList
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IOpportunityWithRelations>(`${this.SERVER}/organizations/${organizationId}/opportunities/count`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({count: response.entity ?? 0});
  }

  @Action(OpportunityActions.CountAllList)
  async countAllOpportunitiesList(
    {patchState}: StateContext<IOpportunityState>,
    {filter}: OpportunityActions.CountAllList
  ): Promise<void> {
    const response: DataBaseServiceResponse<number> = await firstValueFrom(this.baseService.count<IOpportunityWithRelations>(`${this.SERVER}/opportunities/count`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({count: response.entity ?? 0});
  }

  @Action(OpportunityActions.Create)
  async createOpportunity(
    {patchState, getState}: StateContext<IOpportunityState>,
    {organizationId, opportunity}: OpportunityActions.Create
  ): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunity> = await firstValueFrom(this.baseService.post<IOpportunity>(`${this.SERVER}/organizations/${organizationId}/opportunities`, opportunity));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.updateOrPushItems<IOpportunityWithRelations>(getState().list, response.entity!, 'id'),
      selectedId: response.entity!.id!,
    });
  }

  @Action(OpportunityActions.UpdateById)
  async updateOpportunity(
    {patchState, getState}: StateContext<IOpportunityState>,
    {organizationId, opportunityId, opportunity}: OpportunityActions.UpdateById
  ): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityWithRelations> = await firstValueFrom(this.baseService.patch<IOpportunityWithRelations>(`${this.SERVER}/organizations/${organizationId}/opportunities/${opportunityId}`, opportunity));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.updateOrPushItems<IOpportunityWithRelations>(getState().list, response.entity!, 'id'),
      selectedId: response.entity!.id!,
    });
  }

  @Action(OpportunityActions.UpdateAllById)
  async updateAllOpportunity(
    {patchState, getState}: StateContext<IOpportunityState>,
    {opportunityId, opportunity}: OpportunityActions.UpdateAllById
  ): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityWithRelations> = await firstValueFrom(this.baseService.patch<IOpportunityWithRelations>(`${this.SERVER}/opportunities/${opportunityId}`, opportunity));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.updateOrPushItems<IOpportunityWithRelations>(getState().list, response.entity!, 'id'),
      selectedId: response.entity!.id!,
    });
  }

  @Action(OpportunityActions.GetById)
  async getOpportunityById(
    {patchState, getState}: StateContext<IOpportunityState>,
    {organizationId, opportunityId, filter, setBudgetRequest}: OpportunityActions.GetById
  ): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityWithRelations> = await firstValueFrom(this.baseService.get<IOpportunityWithRelations>(`${this.SERVER}/organizations/${organizationId}/opportunities/${opportunityId}`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.updateOrPushItems<IOpportunityWithRelations>(getState().list, response.entity!, 'id'),
      selectedId: response.entity!.id!,
      opportunityMatches: response.entity!.OpportunityMatch ?? [],
      joinOpportunityRequest: response.entity!.JoinOpportunityRequest ?? []
    });

    if (setBudgetRequest) {
      patchState({
        budgetRequest: response.entity!.BudgetRequest ?? [],
      });
    }
  }

  @Action(OpportunityActions.GetAllById)
  async getAllOpportunityById(
    {patchState, getState}: StateContext<IOpportunityState>,
    {opportunityId, filter, setBudgetRequest}: OpportunityActions.GetAllById
  ): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityWithRelations> = await firstValueFrom(this.baseService.get<IOpportunityWithRelations>(`${this.SERVER}/opportunities/${opportunityId}`, filter));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.updateOrPushItems<IOpportunityWithRelations>(getState().list, response.entity!, 'id'),
      selectedId: response.entity!.id!,
      opportunityMatches: response.entity!.OpportunityMatch ?? [],
      joinOpportunityRequest: response.entity!.JoinOpportunityRequest ?? [],
    });

    if (setBudgetRequest) {
      patchState({
        budgetRequest: response.entity!.BudgetRequest ?? [],
      });
    }
  }

  @Action(OpportunityActions.SetOpportunitySelected)
  async setOpportunityById(
    {patchState}: StateContext<IOpportunityState>,
    {id}: OpportunityActions.SetOpportunitySelected
  ): Promise<void> {
    patchState({selectedId: id});
  }

  @Action(OpportunityActions.Reset)
  async resetOpportunity({setState}: StateContext<IOpportunityState>): Promise<void> {
    setState(_DEFAULT_OPPORTUNITY);
  }

  @Action(OpportunityActions.FinalizeById)
  async finalize({patchState, getState}: StateContext<IOpportunityState>, {organizationId, opportunityId}: OpportunityActions.FinalizeById): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityWithRelations> = await firstValueFrom(this.baseService.post<IOpportunityWithRelations>(`${this.SERVER}/organizations/${organizationId}/opportunities/${opportunityId}/finish`, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.updateOrPushItems<IOpportunityWithRelations>(getState().list, response.entity!, 'id'),
    });
  }

  @Action(OpportunityActions.FinalizeAllById)
  async finalizeAllById({getState, patchState}: StateContext<IOpportunityState>, {opportunityId}: OpportunityActions.FinalizeAllById): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityWithRelations> = await firstValueFrom(this.baseService.post<IOpportunityWithRelations>(`${this.SERVER}/opportunities/${opportunityId}/finish`, void 0));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.updateOrPushItems<IOpportunityWithRelations>(getState().list, response.entity!, 'id'),
    });
  }

  @Action(OpportunityActions.DeleteById)
  async delete({patchState, getState}: StateContext<IOpportunityState>, {organizationId, opportunityId}: OpportunityActions.DeleteById): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityWithRelations> = await firstValueFrom(this.baseService.delete<IOpportunityWithRelations>(`${this.SERVER}/organizations/${organizationId}/opportunities/${opportunityId}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.deleteItemByProp<IOpportunityWithRelations>(getState().list, 'id', opportunityId),
    });
  }

  @Action(OpportunityActions.DeleteAllById)
  async deleteAllById({patchState, getState}: StateContext<IOpportunityState>, {opportunityId}: OpportunityActions.DeleteAllById): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityWithRelations> = await firstValueFrom(this.baseService.delete<IOpportunityWithRelations>(`${this.SERVER}/opportunities/${opportunityId}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);

    patchState({
      list: UtilityClass.deleteItemByProp<IOpportunityWithRelations>(getState().list, 'id', opportunityId),
    });
  }

  @Action(OpportunityActions.DownloadQuoteDocument)
  async downloadQuoteDocument(_: StateContext<IOpportunityState>, {opportunityId, budgetRequestId, mimeType, fileName}: OpportunityActions.DownloadQuoteDocument): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/opportunities/${opportunityId}/budget-requests/${budgetRequestId}/download`, mimeType));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    DocumentUtilClass.download(response.entity!, fileName);
  }

  @Action(OpportunityActions.GetResolution)
  async getResolution({getState, patchState}: StateContext<IOpportunityState>, {organizationId, opportunityId, resolutionId}: OpportunityActions.GetResolution): Promise<void> {
    const response: DataBaseServiceResponse<IOpportunityResolutionWithRelations> = await firstValueFrom(this.baseService.get<IOpportunityResolutionWithRelations>(`${this.SERVER}/organizations/${organizationId}/opportunities/${opportunityId}/resolutions/${resolutionId}`));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    patchState({
      resolutions: [response.entity!],
      resolutionsSelectedId: response.entity?.id
    });
  }

  @Action(OpportunityActions.DownloadResolution)
  async DownloadResolution(_: StateContext<IOpportunityState>, {organizationId, opportunityId, resolutionId, acceptedSupplierId, fileName, mimeType}: OpportunityActions.DownloadResolution): Promise<void> {
    const response: DataBaseServiceResponse<Blob> = await firstValueFrom(this.baseService.download(`${this.SERVER}/organizations/${organizationId}/opportunities/${opportunityId}/resolutions/${resolutionId}/accepted-suppliers/${acceptedSupplierId}/download`, mimeType));
    if (response.error) throw this.errorHandlerService.createRequestException(response.serverResponse);
    DocumentUtilClass.download(response.entity!, fileName);
  }
}
